@import "_variables.scss";

.Toastify__toast-container {
  width: 12rem;
}

.Toastify__toast {
  min-height: 3rem;
  padding: 1rem;
}

.Toastify__toast--success {
  background: #0482a2;
}

.select-search__options {
  margin-left: 0;
  padding-left: 0;
}

.react-tag-input__input {
  font-size: 0.875rem;
}

a {
  font-weight: bold;
}

.css-d89191 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  color: rgb(163, 163, 163);
  font-size: 14px;
}

.css-3g2h7c {
  width: 100%;
  background-color: rgb(163, 163, 163);
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  opacity: 0.5;
}

.css-16d8ppv {
  padding: 0px 24px;
}

.account-pages {
  input, select {
    background-color:rgb(247, 250, 251)
  }
}

sub {
  bottom: 0;
}

.custom-date-input {
  cursor: pointer;
  padding: 5px 15px;
  border: 0;
  border-radius: 4px;
  background-color: #216ba5;
  font: inherit;
  color: #fff;
  margin-right: 1rem;
}

strong, b {
  font-weight: 700;
}

.btn-link {
  padding-left: 0;
  padding-right: 0;
}

.card-body {
  padding: 0.75rem;

  @media (min-width: 600px) {
    padding: 1.25rem 1.5rem;
  }
}

.react-tag-input__input {
  color: $red;
}

.rug-card {
  overflow: visible;
}

.rug-items.__card {
  padding-top: 24px;
}

.rug-card .rug-card-remove {
  top: -18px;
  right: -18px;
}

.rug .rug-item {
  padding-bottom: 48px;
}

.rug-card .rug-card-name {
  height: 24px;
  bottom: -24px;
}

.rug-card .rug-card-name,
.rug-card .rug-card-size {
  color: black;
  font-weight: normal;
}