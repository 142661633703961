// 
// _buttons.scss
// 

button,a {
  outline: none !important;
}


// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark{
  color: $gray-200 !important;
}

.btn-outline-dark{
  &:hover{
    color: $gray-200 !important;
  }
}

.btn-outline {
  border-color: $gray-200 !important;

  &:hover{
    border-color: $blue !important;
  }
}

.btn-outline-white {
  background: transparent !important;
  border-color: $white !important;
  color: $white !important;

  &:hover{
    background: $white !important;
    border-color: $black !important;
    color: $black !important;
  }
}

.btn-outline-primary {
  &:hover{
    color: $blue !important;
  }
}
